import '../styles/globals.css';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { OktaProvider } from '../components/OktaContext';
import { Layout } from '@org-crowley/enterprise-react-component-library';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { FeatureFlagProvider } from '../components/FeatureFlagContext';
import { GA_TRACKING_ID } from '@/lib/analytics';
import Script from 'next/script';
import { LayoutContents } from 'components/LayoutContents';
import { useDataDog } from '../utils/useDataDog';

export interface NavTab {
  displayName: string;
  pathString: string;
}

function StarterApp({ Component, pageProps }: AppProps) {
  useDataDog();
  const [userName, setUserName] = useState('');
  const router = useRouter();

  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script id="google-analytics" strategy="lazyOnload">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${GA_TRACKING_ID}');
        `}
      </Script>
      <Head>
        <title>React Starter</title>
      </Head>
      <OktaProvider setUserName={setUserName} userName={userName}>
        <FeatureFlagProvider>
          <Layout
            masthead={{
              title: 'React Starter',
              onLogoClick: () => {
                router.push('/');
              },
              name: userName
            }}
          >
            <LayoutContents>
              <Component {...pageProps}></Component>
            </LayoutContents>
          </Layout>
        </FeatureFlagProvider>
      </OktaProvider>
    </>
  );
}

export default StarterApp;
